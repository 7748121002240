<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :search="search"
      :items="modelTable.content"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
      :server-items-length="modelTable.totalElements"
      :options.sync="options"
      :page.sync="page"
      :loading="loading"
      :item-class="itemRowBackground"
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
          >
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchbar"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              label="Buscar..."
              :disabled="loading_search"
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  >
                  </i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip v-if="isDetail" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand pr-3"
              @click="detail(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-eye pt-5"></i>
            </span>
          </template>
          <span> {{ $t("table.detail") }} </span>
        </v-tooltip>

        <v-tooltip top>
          <template v-if="isButtonPDF" v-slot:activator="{ on, attrs }">
            <span
              class="hand text-fmedical-red"
              @click="downloadPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fa fa-file-pdf-o"></i>
            </span>
          </template>
          <span>{{ $t("table.download_PDF") }}</span>
        </v-tooltip>
        <div v-if="!isButtonPDF && !isDetail">
          -
        </div>
      </template>
      <template v-slot:[`item.statusName`]="{ item }">
        <ChipTableCommonPagination :status="item.statusShipment" />
      </template>
      <!--   <template v-slot:[`item.status`]="{ item }">
        <v-chip color="green" dark class="m-1">
          {{item}}
        </v-chip>
      </template> -->
    </v-data-table>
    <ButtonTableComponent v-if="isButton" v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import ChipTableCommonPagination from "@/common/table/ChipTableCommonPagination";

export default {
  props: {
    headers: {},
    isButton: false,
    showSelect: false,
    pickingStatus: 0,
    isDetail: false,
    isButtonPDF: false,
    /* Pagination */
    methodPage: Function,
    pagesAtributes: Object,
    modelTable: Object,
  },
  data() {
    return {
      search: "",
      options: {},
      loading: false,
      page: 1,
      loading_search: false,
    };
  },
  components: {
    ButtonTableComponent,
    ChipTableCommonPagination,
  },
  methods: {
    /**
     * Un método que se llama cuando cambia la paginación.
     * @method
     */
    async pagination() {
      this.pagesAtributes.sortBy =
        this.options.sortBy.length > 0 ? this.options.sortBy[0] : "";
      if (this.time > 1)
        this.pagesAtributes.sortDesc = this.options.sortDesc[0];
      this.time++;
      this.pagesAtributes.currentPage = this.options.page - 1;
      this.pagesAtributes.itemsPerPage = this.options.itemsPerPage;
      await this.methodPage(this.pagesAtributes);
    },
    /**
     * Método que se llama cuando se utiliza la barra de búsqueda.
     * @method
     */
    async searchbar() {
      try {
        this.loading_search = true;
        this.pagesAtributes.search = this.search;
        await this.performSearch(this.pagesAtributes);
        this.page = 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_search = false;
      }
    },
    async performSearch(pagesAtributes) {
      await this.methodPage(pagesAtributes);
    },
    reloadPages() {
      this.page = 1;
    },
    /* General */
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    reloadComponentClean() {
      this.dataList = [];
    },
    deleteRecord(item) {
      this.$emit("deleteRecord", item);
    },
    updateRecord(item) {
      this.$emit("updateRecord", item);
    },
    viewRecord(item) {
      this.$emit("viewRecord", item, 0, item.status);
    },
    detail(item) {
      this.$emit("detail", item);
    },
    downloadPDF(item) {
      this.$emit("downloadPDF", item);
    },
    excelTable() {
      this.$emit("excelTable");
    },
    itemRowBackground: function(item, index) {
      return item.status == 7 ? "style-2" : "";
    },
  },
  watch: {
    search() {
      if (this.search == "") {
        this.searchbar();
      }
    },
    options: {
      handler() {
        this.pagination();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.style-2 {
  background-color: rgb(254, 187, 187);
}
</style>
