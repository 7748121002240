var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.modelTable.content,"height":"auto","fixed-header":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":"","server-items-length":_vm.modelTable.totalElements,"options":_vm.options,"page":_vm.page,"loading":_vm.loading,"item-class":_vm.itemRowBackground},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"},[_c('v-text-field',{staticClass:"br",attrs:{"suffix":_vm.search == '' ? '' : 'enter',"label":"Buscar...","disabled":_vm.loading_search},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchbar.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pt-2"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.search != ''),expression:"search != ''"}],staticClass:"feather icon-corner-down-left"})])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand pr-3",on:{"click":function($event){return _vm.detail(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye pt-5"})])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("table.detail"))+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.isButtonPDF)?{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-red",on:{"click":function($event){return _vm.downloadPDF(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"fa fa-file-pdf-o"})])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.download_PDF")))])]),(!_vm.isButtonPDF && !_vm.isDetail)?_c('div',[_vm._v(" - ")]):_vm._e()]}},{key:"item.statusName",fn:function(ref){
    var item = ref.item;
return [_c('ChipTableCommonPagination',{attrs:{"status":item.statusShipment}})]}}],null,true)}),(_vm.isButton)?_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }