<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i class="feather icon-paperclip mr-1"></i>
              {{ $t("shipments_history.detail_order") }}
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="cleanModal()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="container">
          <div class="row">
            <AlertMessageComponent ref="alertModal" />
            <div class="col-md-12 pb-0">
              <div class="card border-light">
                <div class="card-header pb-2 card-header-detail">
                  <h6>
                    <i class="fa fa-building-o mr-2 text-fmedical-blue"></i>
                    <strong>
                      <span>
                        {{ $t("picking.destination_branch") }}
                      </span>
                    </strong>
                    {{ currentItem.branchOfficeDestinationName }}
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xs-12 col-md-4">
                      <p>
                        <i
                          class="feather icon-truck mr-1 text-fmedical-blue"
                        ></i>
                        <strong>
                          <span> {{ $t("picking.shipping_id") }}</span>
                        </strong>
                        {{ currentItem.idShipment }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-md-4">
                      <p>
                        <i
                          class="feather icon-clipboard mr-1 text-fmedical-blue"
                        ></i>
                        <strong>
                          <span> {{ $t("picking.order_id") }}</span>
                        </strong>
                        {{ currentItem.orderId }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-md-4">
                      <p>
                        <i
                          class="feather icon-user mr-1 text-fmedical-blue"
                        ></i>
                        <strong>
                          <span> Generado por:</span>
                        </strong>
                        {{ currentItem.userFullName }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <p>
                        <i
                          class="feather icon-calendar mr-1 text-fmedical-blue"
                        ></i>
                        <strong>
                          <span> Fecha de creación:</span>
                        </strong>
                        {{ currentItem.userFullName }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <p>
                        <i
                          class="feather icon-calendar mr-1 text-fmedical-blue"
                        ></i>
                        <strong>
                          <span> Fecha de envio:</span>
                        </strong>
                        {{ currentItem.dateShipment }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <v-data-table
                :headers="orderProductsHeaders"
                :items="dataList"
                height="auto"
                :search="search"
                :footer-props="{
                  'items-per-page-text': $t('table.item_table'),
                  'items-per-page-all-text': $t('table.all'),
                  'items-per-page-options': [10, 25, 50, -1],
                }"
                :loading-text="$t('table.loading')"
                :no-data-text="$t('table.no_results_available')"
                :no-results-text="$t('table.no_results_search')"
                dense
              >
                <template v-slot:top>
                  <div class="row justify-end">
                    <div
                      class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
                    >
                      <v-text-field
                        v-model="search"
                        :label="$t('table.search')"
                      ></v-text-field>
                    </div>
                  </div>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    v-if="item.warehouseId == 1"
                    color="green"
                    dark
                    class="m-1"
                  >
                    Activo
                  </v-chip>
                  <v-chip
                    v-if="item.warehouseId == 2"
                    color="red"
                    dark
                    class="m-1"
                  >
                    Producto no conforme
                  </v-chip>
                </template>
              </v-data-table>

              <ButtonTableComponent v-on:excelTable="excelTable" />
            </div>
          </div>
        </div>
        <div class="row mt-8"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { UtilFront } from "@/core";
import { OrderStockRequest, ExcelRequest } from "@/core/request";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import { PICKING_ORDER } from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      orderProductsHeaders: PICKING_ORDER(),
      dataList: [],
      item: {},
      dialog: false,
      loading: null,
      search: "",
      currentItem: {
        branchOfficeDestination: {},
        shipment: {},
        isView: false,
      },
    };
  },
  components: {
    ButtonTableComponent,
    AlertMessageComponent,
  },
  mounted() {},
  methods: {
    cleanModal() {
      this.dataList = [];
      this.dialog = false;
    },
    reloadComponent(item) {
      //Mustro la pantalla de cargando
      this.loading = UtilFront.getLoadding();
      //Abro el modal
      this.dialog = true;

      this.currentItem = item;

      OrderStockRequest.orderStockDetail({
        idOrder: item.orderId,
        status: [1, 3, 4, 6],
      })
        .then((response) => {
          let { status, data } = response;

          if (status == 200) {
            this.dataList = data;
          } else if (status == 202) {
            this.$refs.alertModal.reloadComponent("error", data);
          }
        })
        .catch((error) => {
          this.$refs.alertModal.reloadComponent("internal", "");
        })
        .finally(() => {
          this.orderProductsHeaders = PICKING_ORDER();
          //Elimino la pantalla de cargando
          this.loading.hide();
        });
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");

      ExcelRequest.reportShipmentsDetails({
        idOrder: this.currentItem.idOrder,
      });
    },
  },
};
</script>
