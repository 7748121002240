<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-xs-12 col-md-12 col-lg-12 col-xl-7">
            <!--Se agrego el "col-xs-12"-->
            <h4 class="page-title">{{ $t("shipments_history.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("shipments_history.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="/salidas">{{ $t("shipments_history.history") }}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- Start Contentbar -->
      <div class="contentbar">
        <div class="row justify-content-center">
          <!-- Start col -->
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="card m-b-30">
              <div class="row">
                <div class="col-lg-12 col-xl-12">
                  <div class="card m-b-30">
                    <div class="card-header">
                      <div class="row align-items-center">
                        <div class="col-12">
                          <h5 align="left" class="card-title mb-0">
                            <i class="ti-truck mr-2"></i
                            >{{ $t("shipments_history.shipments_history") }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ShipmentsHistoryTableComponent
                    v-if="showTable"
                    :modelTable="listShipmentsHistory"
                    :pagesAtributes="pagesAtributes"
                    :methodPage="getShipmentsHistory"
                    :headers="pickingMovHeaders"
                    :isButton="true"
                    v-on:downloadPDF="downloadPDF"
                    v-on:excelTable="excelTable"
                    v-on:detail="detail"
                    :isDetail="
                      withPermissionName(permissions.SHIP_HIST_DETA_01)
                    "
                    :isButtonPDF="
                      withPermissionName(permissions.SHIP_HIST_DL_PDF_02)
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- End col -->
        </div>
      </div>
    </div>
    <ModalOrderProductComponent ref="modalOrderDetail" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { UtilFront, PermissionConstants } from "@/core";
import { ShipmentsRequest, ExcelRequest } from "@/core/request";
import SelectBranchOffice from "@/common/select/SelectBranchOffice.vue";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import ShipmentsHistoryTableComponent from "@/components/shipments/ShipmentsHistoryTableComponent";
import ModalOrderProductComponent from "@/common/modal/ModalOrderProductComponent";
import { SHIPMENTS_HISTORY } from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      title: "shipments_history.shipments",

      /* Cabeceras */
      pickingMovHeaders: SHIPMENTS_HISTORY(),

      /* General */
      idUser: "",
      action: 1,
      loading: null,
      itemOrigin: "",
      //originId

      /* Listas de picking y validacion */
      pickingMovList: [],
      permissions: PermissionConstants,

      /* Pagination */
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortFilters: {},
      },
      listShipmentsHistory: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      showTable: true,
    };
  },
  computed: {
    ...mapState("auth", {
      branchOffice: "branchOffice",
    }),
  },
  methods: {
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    async getShipmentsHistory(page) {
      this.loading = UtilFront.getLoadding();
      await ShipmentsRequest.listAllShipmentsByType({
        page,
        type: 0,
        idBranchOffice: this.branchOffice.idBranchoffice,
        idUser: UtilFront.getDataUser().idUser,
      })
        .then((response) => {
          let { data } = response;
          this.listShipmentsHistory = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    downloadPDF(item) {
      let send =
        "idOrder=" + item.orderId + "&" + "idShipment=" + item.idShipment;
      ExcelRequest.reportOrderPdf(send);
      this.$toast.success("Generando documento (documento.pdf)");
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportShipments({
        type: 0,
        userBranchOffice: {
          idBranchOffice: this.branchOffice.idBranchoffice
        },
      });
    },
    detail(item) {
      this.$refs.modalOrderDetail.reloadComponent(item);
    },
  },
  created() {
    this.idUser = UtilFront.getDataUser().idUser;
    this.updateAction(1);
  },
  watch: {
    async branchOffice() {
      this.showTable = false;
      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortFilters: {},
      };
      await this.getShipmentsHistory(this.pagesAtributes);
      this.$nextTick(() => {
        this.showTable = true;
      });
    },
    "$i18n.locale": function() {
      this.pickingMovHeaders = SHIPMENTS_HISTORIC();
    },
  },
  components: {
    SelectBranchOffice,
    AlertMessageComponent,
    ShipmentsHistoryTableComponent,
    ModalOrderProductComponent,
  },
};
</script>
